<template>
  <div class="order-detail">
    <van-sticky>
      <van-config-provider>
        <van-nav-bar :title="$t('title.detail')" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
      </van-config-provider>
    </van-sticky>
    <div class="order-list">
      <div v-if="$AppData.config.themeType" class="journal-block white">
        <van-cell-group :border="false">
          <div class="journal-detail">
            <van-cell :title="$t('field.orderNumber')" :value="orderDetail.sn" class="journal-detail-row" />
            <van-cell :title="$t('field.amount')" :value="`${orderDetail.amountStr} ${$AppData.config.appCoin}`" class="journal-detail-row" />
            <van-cell :title="$t('field.income2')" :value="`${orderDetail.incomeStr} ${$AppData.config.appCoin}`" class="journal-detail-row" />
            <!-- <van-cell :title="$t('field.contribution')" :value="orderDetail.contribution" class="journal-detail-row" /> -->
            <van-cell :title="$t('field.account')" :value="orderDetail.qrCodeAccount" class="journal-detail-row" />
            <van-cell :title="$t('field.orderTime')" :value="orderDetail.orderTakingTimeStr" class="journal-detail-row" />
          </div>
          <div class="btn-wrap">
            <van-button v-if="coldDown>0" type="danger" size="small" round disabled>{{$t('button.fail')}} {{coldDown}}</van-button>
            <van-button v-else type="danger" size="small" round plain @click="handleFailOrder">{{$t('button.fail')}}</van-button>
            <van-button type="primary" round size="small" @click="handleDoneOrder">{{$t('button.completed')}}</van-button>
          </div>
        </van-cell-group>
      </div>
      <div v-else class="journal-block">
        <van-cell-group>
          <van-cell :title="$t('field.orderNumber')" :value="orderDetail.sn" class="journal-title" value-class="journal-sn"/>
          <div class="journal-detail">
            <van-cell :title="$t('field.amount')" :value="`${orderDetail.amountStr} ${$AppData.config.appCoin}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
            <van-cell :title="$t('field.income2')" :value="`${orderDetail.incomeStr} ${$AppData.config.appCoin}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
            <!-- <van-cell :title="$t('field.contribution')" :value="orderDetail.contribution" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" /> -->
            <van-cell :title="$t('field.account')" :value="orderDetail.qrCodeAccount" :border="false" class="journal-detail-row" title-class="journal-label" />
            <van-cell :title="$t('field.orderTime')" :value="orderDetail.orderTakingTimeStr" :border="false" class="journal-detail-row" title-class="journal-label"/>
          </div>
          <div class="btn-wrap">
            <van-button v-if="coldDown>0" type="danger" size="small" round disabled>{{$t('button.fail')}}{{coldDown}}</van-button>
            <van-button v-else type="danger" size="small" round plain @click="handleFailOrder">{{$t('button.fail')}}</van-button>
            <van-button type="danger" round size="small" @click="handleDoneOrder">{{$t('button.completed')}}</van-button>
          </div>
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/assets/lang/index';
import { doneOrder, failOrder } from "@/api";

export default {
  data() {
    return {
      orderDetail: JSON.parse(localStorage.getItem('orderDetail')),
      coldDown: 0,
      coldDownInterval: false,
    };
  },
  
  mounted() {
    this.coldDown = this.orderDetail.expire;
    this.coldDownInterval = setInterval(() => {
      this.coldDown = this.coldDown - 1;
      if (this.coldDown === 0) {
        clearInterval(this.coldDownInterval);
      }
    }, 1000);
  },
  
  unmounted() {
    if (this.coldDownInterval) {
      clearInterval(this.coldDownInterval);
    }
  },

  methods: {
    handleDoneOrder() {
      this.$myDialog.confirm({
        title: i18n.global.t('alert.warnning'),
        message: i18n.global.t('alert.hasReceived').format({
          1: this.orderDetail.qrCodeAccount, 
          2: this.orderDetail.amountStr, 
          3: this.$AppData.config.appCoin,
        }),
      })
      .then(() => {// on confirm
        doneOrder({ sn: this.orderDetail.sn }).then(res => {
          this.goBack();
        });
      })
      .catch(() => {});
    },
    handleFailOrder() {
      this.$myDialog.confirm({
        title: i18n.global.t('alert.warnning'),
        message: i18n.global.t('alert.notReceived'),
      })
      .then(() => {// on confirm
        failOrder({ sn: this.orderDetail.sn }).then(res => {
          this.goBack();
        });
      })
      .catch(() => {});
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.order-detail {
}
</style>
